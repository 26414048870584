import {
    Table,
  } from "antd";
import moment from "moment";
import type { ColumnsType } from 'antd/es/table';

const TransactionTable = ({list,loading}:any) => {
    const columns: ColumnsType<any> = [
     
        {
          title: "Transaction Id",
          dataIndex: "transaction_id",
          key: "title",
        },    
        {
          title: "Amount",
          dataIndex: "amount",
          key: "key",
        },  
        {
            title: "Status",
            dataIndex: "status",
            key: "title",
          },    
          {
            title: "Regard To",
            dataIndex: "transaction_mode",
            key: "key",
          },  
        {
          title: "Created On",
          key: "created_at",
          dataIndex: "created_at",
          render: ({ created_at }:any) => {
            return moment(created_at).format("MM-DD-YYYY");
          },
        },
      ];
    return (
        // <div className="table-responsive customPagination"  style={{backgroundColor:"black !important"}}>
                  <Table
                  rowClassName="tableClass"
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                    //   total: pagination.total,
                      showSizeChanger: true,
                      showQuickJumper: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    // onChange={handleChange}
                    className="ant-border-space"
                  />
                // </div>
    )
}

export default TransactionTable
import { memo, useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"



const Header = () => {
  const [icon, setIcon] = useState<boolean>(false)
  const {isLoggedIn, userProfile,logout} = useContext(AuthContext)
  let location = useLocation()
  let url = location?.pathname
  const [token , setToken] = useState(null)
  useEffect(()=>{
    // Run();
  },[])

; // Replace with your actual Telegram channel link

    let tokenn = localStorage.getItem('token')
    // setToken(tokenn)
  
//   function openWhatsApp() {
//     const phoneNumber = '+918093023262'; // Replace with your WhatsApp number
//     const message = 'Hello! I have a query.'; // Replace with your default message
//     const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
//     window.open(url, '_blank');


// }
function openWhatsApp() {
  const phoneNumber = '+918093023262'; // Replace with your WhatsApp number
  const message = 'Hello! I have a query.'; // Replace with your default message
  const telegramLink = 'https://telegram.me/gamezonofficial'
  window.open(telegramLink, '_blank');


}


    return (
        <nav className="navbar navbar-expand-lg in_nav">
        <div className="container">
          <Link className="navbar-brand mr-md-5" to="/account">
            <img src="../../img/logo.png" alt="header-Logo" className="logo" /></Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" onClick={()=>setIcon(!icon)} data-target="#navbarText">
            <span className="icon-bar"><i className="fa fa-bars fa-2x"></i></span>
          </button>
          <div className={icon ? "collapse navbar-collapse show":"collapse navbar-collapse"} id="navbarText">
            <ul className="navbar-nav ml-auto line">
       
              {/* <li className="nav-item">
                <Link className={url == "/"?"nav-link active":"nav-link"} to="/" >Home</Link>
              </li> */}
              <li className="nav-item">
                <Link className={url == "/promotion"?"nav-link active":"nav-link"} to="/promotion" >Promotion</Link>
              </li>
              <li className="nav-item">
                <Link className={url == "/wallet"?"nav-link active":"nav-link"} to="/wallet" >Wallet</Link>
              </li>
              <li className="nav-item">
                <Link className={url == "/account"?"nav-link active":"nav-link"} to="/account" >Account </Link>
              </li>
              <Link className={url == "/game"?"nav-link active":"nav-link"} to={'#'} onClick={openWhatsApp} target="_blank" >Support Channel </Link>

          
              <li className="nav-item">
                <Link className={url == "/game"?"nav-link active":"nav-link"} to={ tokenn ? "/games" :"/game"} target="_blank" >Game Play </Link>
              </li>
            </ul>
       
          </div>
          
            <div className="top_button d-flex">
         {isLoggedIn?<><div className="btn   ml-md-4">{userProfile?.name?userProfile?.name:userProfile?.mobile_number} </div>
         <div className="btn  ml-2" onClick={logout}>Logout</div></>: <><Link className="btn  ml-2 ml-md-3" to="/login"> LogIn</Link>
          <Link className="btn  ml-2" to="/register"> Register</Link></> }      
        </div>
        </div>
       
       </nav>
    )
}

export default memo(Header)
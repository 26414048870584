import { createContext, useContext, useEffect, useRef, useState } from "react";

import { baseURL } from "../constant/ApiRoutes";
import io from "socket.io-client";
import { Severty, ShowToast } from "../helper/toast";
import { WalletContext } from "./WalletContext";
import useGame from "../hooks/useGame";

export const socket = io(baseURL, {
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const SocketContext = createContext<any>({});
export const SocketProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gameData, setGameData] = useState<object>({});
  const [x, setX] = useState<number>(0);
  const [winnerInfo, setWinnerInfo] = useState<Object>({});
  const [isGameEnd, setIsGameEnd] = useState<boolean>(false);
  const [userCurrentBets, setUserCurrentBets] = useState<any[]>([]);
  const [winnerList, setWinnerList] = useState<any[]>([]);
  const [bets, setBets] = useState<any[]>([]);
  const [totalBets, setTotalbets] = useState<number>(0);
  const [userBets, setUserBets] = useState<any[]>([]);
  const [firstBoxFutureBet, setFirstBoxFutureBet] = useState<any>({});
  const [secondBoxFutureBet, setSecondBoxFutureBet] = useState<any>({});
  const { walletData } = useContext(WalletContext);


  useEffect(() => {
    setIsLoading(true);
    socket.on("connection", (val) => {
      console.log("connected:", val);
    });
    socket.on("xValue", getTimer);
    socket.on("gameData", getGameData);
    socket.on("resultAnouncement", resultAnoucement);
    socket.on("betCount", betCount);
    // return () => {
    //   if (socket) {
    // 	socket.disconnect();
    //   }
    // };
    return () => {
      socket.off("xValue", getTimer);
      socket.off("resultAnouncement", resultAnoucement);
      socket.off("gameData", getGameData);
      socket.off("betCount", betCount);
    };
  }, []);

  const resultAnoucement = (resp: any) => {
    console.log("gameEndTimergameEndTimer", gameData);
    setWinnerInfo(resp);
    walletData();
  };
  const betCount = (resp: any) => {
    console.log("betCountbetCountbetCount", resp);
    setTotalbets(resp);
  };
  const getTimer = (data: any) => {
    setIsLoading(false);
    if (data?.data?.gameEndTimer > 0) {
      setX(data.data.gameEndTimer);
      setIsGameEnd(false);
      setWinnerInfo({});
    } else {
      setX(data.data.gameEndTimer);
      setIsGameEnd(true);
    }
  };

  const getGameData = (gameData: any) => {
    setGameData(gameData?.data?.current_game);
    setWinnerList(gameData?.data?.winnerList);
    setIsLoading(false);
  };

  return (
    <>
      <SocketContext.Provider
        value={{
          isLoading,
          x,
          totalBets,
          userBets,
          bets,
          winnerList,
          userCurrentBets,
          isGameEnd,
          gameData,
          winnerInfo,
          setFirstBoxFutureBet,
          setSecondBoxFutureBet,
          firstBoxFutureBet,
          secondBoxFutureBet,
        }}
      >
        {children}
      </SocketContext.Provider>
    </>
  );
};

import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useRequest from "../hooks/useRequest";
import { apiPath } from "../constant/ApiRoutes";
import { Severty, ShowToast } from "../helper/toast";
import { AuthContext } from "./AuthContext";

export const WalletContext = createContext<any>({})
const WalletProvider = ({children}:any) => {
    const [walletDetails, setWalaletDetails] = useState<object>({})
    const { isLoggedIn } = useContext(AuthContext)

    const { request }  = useRequest()

    useEffect(() => {
      if (isLoggedIn) {
        walletData();
      }
    }, [isLoggedIn]);

    const walletData = () => {
      const token  = localStorage.getItem("token")
      // if(token){
        request({
           url: apiPath.getWalletBalance,
           method: "GET",
           onSuccess: (data) => {
             if (data.status) {
              setWalaletDetails(data.data)
             } else {
               ShowToast(data.message, Severty.ERROR);
             }
           },
           onError: (error) => {
             ShowToast(error.response.data.message, Severty.ERROR);
           },
         });
        // }
    }
    const value = useMemo(() => ({ walletDetails, walletData }), [walletDetails]);

    return (
      <>
      <WalletContext.Provider value={value}>
          {children}
      </WalletContext.Provider>
    </>
    )
}

export default WalletProvider
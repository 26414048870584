import { useContext, useEffect, useState } from 'react';

import { apiPath, baseURL } from '../constant/ApiRoutes';
import { Severty, ShowToast } from '../helper/toast';
import useRequest from './useRequest';
import { WalletContext } from '../context/WalletContext';

const useGame = () => {
	const [userBets, setUserBets] = useState<any[]>([]);
	const [minBetAmount, setMinBetAmount] = useState<number>(10);
	const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
	const {walletData, walletDetails} = useContext(WalletContext)
	
	

	const { request } = useRequest();

	const fetchBets = () => {
		request({
			url: apiPath.getBets,
			method: 'GET',
			onSuccess: ({ data, status }) => {
				console.log("pagepsegjsdjfbsdjbfs",data)
				setUserBets(data)
			},
			onError: (error) => {
				ShowToast(error, Severty.ERROR);
			},
		});
	};

	useEffect(()=>{
		fetchBets()
	},[])

	const handleDeposit = ({betAmount,boxId}:any) => {
			if (walletDetails?.balance < betAmount) {
			ShowToast("You Don't have sufficient balance", Severty.ERROR);
		} else if (!boxId) {
			ShowToast(`Please select any box first!!`, Severty.ERROR);
		}
		else if (!betAmount || betAmount<minBetAmount) {
			ShowToast(`Min Bet should be of ${minBetAmount}`, Severty.ERROR);
		} else {
			setIsButtonDisable(true)
			let payload = {
				boxId:boxId,
				amount:betAmount
			}
			request({
				url: apiPath.gameDeposit,
				method: 'POST',
				data: payload,
				onSuccess: ({ data, message, status }) => {
					if (status) {
						ShowToast(message, Severty.SUCCESS);
						walletData()
						fetchBets()
					}
				},
				onError: (error) => {
					ShowToast(error, Severty.ERROR);
				},
			});
		}
	};
	return {
		handleDeposit,
		isButtonDisable,
		setIsButtonDisable,
		fixData: {
			minBetAmount,
			balance:walletDetails?.balance,
		},
		fetchBets,
		userBets
	};
};

export default useGame;

import { Link } from "react-router-dom";
import { Footer } from "../../comman/Footer";
import Header from "../../comman/Header";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  ProfileUserInput,
  ProfileUserInputError,
} from "../../Interface/Profile";
import { ProfileVal } from "../../validation/ProfileVal";
import useRequest from "../../hooks/useRequest";
import { apiPath, baseLOCALURL, baseURL } from "../../constant/ApiRoutes";
import { Severty, ShowToast } from "../../helper/toast";
import BankModel from "../../component/BankModel";
import { WalletContext } from "../../context/WalletContext";
import { AvatarGenerate } from "../../component/Avtar";
import Loader from "../../component/Loader";

export const Account = () => {
  const [userInput, setUserInput] = useState<ProfileUserInput>({
    name: "",
    email: "",
    mobile_number: "",
    gender: "",
    dob: "",
  });
  const [userInputError, setUserInputError] = useState<ProfileUserInputError>({
    nameError: "",
    emailError: "",
    mobile_numberError: "",
    genderError: "",
    dobError: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copy, setCopy] = useState<string>("");
  const { isLoggedIn, userProfile, logout, getProfile } =
    useContext(AuthContext);
  const { walletDetails } = useContext(WalletContext);
  console.log("object", isLoggedIn, userProfile);
  const { request } = useRequest();
  useEffect(() => {
    if (userProfile) {
      setUserInput({
        name: userProfile.name || "",
        email: userProfile.email || "",
        mobile_number: userProfile.mobile_number || "",
        gender: userProfile.gender || "",
        dob: userProfile.dob || "",
      });
    }
  }, [isLoggedIn]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCopy = (e: string) => {
    navigator.clipboard.writeText(e);
    setCopy(e)
  };

  const handleChange = async (e: any) => {
    let { name, value } = e.target;
    const validation = await ProfileVal(name, value);
    setUserInputError((prevError) => ({
      ...prevError,
      [name + "Error"]: validation,
    }));
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      userInputError.dobError ||
      userInputError.genderError ||
      userInputError.nameError
    )
      return false;
    const payload = userInput;
    setIsLoading(true);
    request({
      url: apiPath.updateProfile,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setIsLoading(false);
        if (data.status) {
          getProfile();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <Header />
      <div className="in_padding">
        <div className="container">
          <div className="row ">
            <div className="col-md-4 ">
              <div className="white_box ">
                <div className="d-flex align-items-center pb-3">
                  <div className="uploader">
                    {/* <label htmlFor="file-upload" id="file-drag"></label> */}
                    <div id="start">
                      <span className="fasi">
                        <AvatarGenerate
                          url={userProfile?.social_image}
                          name={userProfile?.name}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="pl-3">
                    <h4 className="">{userInput?.name}</h4>
                    {/* <h5 className="">UID | 2535225 </h5> */}
                  </div>
                </div>
                <hr />
                <div className="text-center">
                  <h2 className=" mb-0 mt-4 font_n">
                    <i className="fa fa-inr"></i>
                    {walletDetails?.balance?.toFixed(2) || 0}
                  </h2>
                  <p> Total Balance</p>
                </div>

                <hr />
                <Link className="d-flex  align-items-center" to="/notification">
                  <i className="fa fa-bell mr-2"></i> Notification{" "}
                  <i className="fa fa-angle-right ml-auto"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-8 mt-md-0 mt-4">
              <div className="white_box mb-4">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 className=" mb-3">Personal Data</h5>
                  {(!userProfile?.bank_info ||
                    Object.keys(userProfile?.bank_info).length === 0) && (
                    <button
                      className="btn btn_man"
                      type="button"
                      onClick={handleOpen}
                    >
                      +Add Bank
                    </button>
                  )}
                </div>
                <form id="update-user">
                  <div className="">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input
                          id="user-update-email"
                          name="email"
                          type="text"
                          className="form-control"
                          disabled={true}
                          value={userInput.email}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Mobile Number</label>
                        <input
                          id="user-update-mobile_number"
                          name="mobile_number"
                          type="number"
                          className="form-control"
                          disabled={true}
                          value={userInput.mobile_number}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Name</label>
                        <input
                          id="user-update-name"
                          name="name"
                          type="text"
                          className="form-control"
                          value={userInput.name}
                          onChange={handleChange}
                        />
                        <span className="text-danger">
                          {userInputError.nameError}
                        </span>
                      </div>

                      <div className="form-group col-md-6">
                        <label>Date of Birth</label>
                        <input
                          id="user-update-dob"
                          name="dob"
                          type="date"
                          max-length="8"
                          className="form-control"
                          value={userInput.dob}
                          onChange={handleChange}
                        />
                        <span className="text-danger">
                          {userInputError.dobError}
                        </span>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Gender</label>
                        <select
                          className="form-control"
                          style={{ height: "auto" }}
                          name="gender"
                          value={userInput.gender}
                          onChange={handleChange}
                          id="user-update-gender"
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Eunuch">Eunuch</option>
                        </select>
                        <span className="text-danger">
                          {userInputError.genderError}
                        </span>
                      </div>
                    </div>
                    <button
                      className="btn btn_man"
                      type="button"
                      onClick={handleSubmit}
                      disabled={
                        !userInput.name ||
                        !userInput.dob ||
                        !userInput.gender ||
                        isLoading
                          ? true
                          : false
                      }
                    >
                      {isLoading && <Loader />} Save
                    </button>
                  </div>
                </form>
              </div>

              <div className="white_box mb-4">
                <h5 className=" mb-3">Bank Detail</h5>
                <form id="update-user">
                  <div className="">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          Account Holder Name :-{" "}
                          {userProfile?.bank_info?.account_holder}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          IFSC Code :- {userProfile?.bank_info?.ifsc_code}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          Account Number :-{" "}
                          {userProfile?.bank_info?.account_number}
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="white_box">
                <label>Referral Code</label>

                <div className="form-group ">
                  <div className="copy_input">
                    <input
                      id="user-update-mobile"
                      type="text"
                      className="form-control"
                      value={userProfile?.referral_id}
                    />
                    {userProfile?.referral_id !== copy?<i
                      className="fa fa-copy"
                      onClick={() => handleCopy(userProfile?.referral_id)}
                    ></i>: <i className="fa fa-check"></i>}
                  </div>
                </div>
                <label>Referral Link</label>
                <div className="form-group ">
                  <div className="copy_input">
                    <input
                      id="user-update-mobile"
                      type="text"
                      className="form-control"
                      value={`${baseLOCALURL}register/referral/${userProfile?.referral_id}`}
                    />
                    {`${baseLOCALURL}register/referral/${userProfile?.referral_id}` !== copy ? <i
                      className="fa fa-copy"
                      onClick={() =>
                        handleCopy(
                          `${baseLOCALURL}register/referral/${userProfile?.referral_id}`
                        )
                      }
                    ></i>:
                    <i className="fa fa-check"></i>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BankModel
        isOpen={open}
        setOpen={setOpen}
        getProfile={getProfile}
      />
      <Footer />
    </>
  );
};

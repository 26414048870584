import Loader from "../../component/Loader";

export const LoaderPage = () => {
  return (
    <>
      <div className="loading-page">
        <div className="m-auto">
          <div className="ring ">
            Loading
            <span></span>
          </div>
          {/* <div >
                <img src="../../img/horse-running.gif" width={200} height={100}/>
            </div> */}
          <h3 className="mt-4">Game Is Starting ...</h3>
          {/* <Loader /> */}
        </div>
      </div>
    </>
  );
};

import { Navigate, Route, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "../pages/Home";
import { NotFound } from "../pages/NotFound";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { Promotion } from "../pages/Promotion";
import { Wallet } from "../pages/Wallet";
import { Account } from "../pages/Account";
import { Game } from "../pages/Game";
import { Suspense, useContext } from "react";
import Loader from "../component/Loader";
import { Notification } from "../pages/Notification";
import { AuthContext, AuthProvider } from "../context/AuthContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SettingProvider from "../context/SettingContext";
import WalletProvider from "../context/WalletContext";
import Payment from "../pages/Payment";
import { PrivateRoute } from "./PrivateRoute";
import PublicRoute from "./PublicRoute";



export const Routes = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const router = createBrowserRouter([
        {
            path: "/",
            element: <PublicRoute element={Login} />
        },
        {
            path: "/login",
            element: <PublicRoute element={Login} />
        },
        {
            path: "/register",
            element: <PublicRoute element={Register} />
        },
        {
            path: "/register/referral/:rfCode",
            element: <PublicRoute element={Register} />
        },
        {
            path: "/promotion",
            element: <PrivateRoute element={Promotion} />
        },
        {
            path: "/wallet",
            element: <PrivateRoute element={Wallet} />
        },
        {
            path: "/account",
            element: <PrivateRoute element={Account} />
        },
        {
            path: "/game",
            element: <PublicRoute element={Game} />
        },
        {
            path: "/games",
            element: <PrivateRoute element={Game} />
        },
        {
            path: "/notification",
            element: <PrivateRoute element={Notification} />
        },
        {
            path: "/payment",
            element: <PrivateRoute element={Payment} />
        },
        {
            path: "/*",
            element: <NotFound />
        }
    ]);

    return (
        <>
            <AuthProvider>
                <SettingProvider>
                    <WalletProvider>
                        <Suspense fallback={<Loader />}>
                            <RouterProvider router={router} />
                        </Suspense>
                    </WalletProvider>
                </SettingProvider>
            </AuthProvider>
            <ToastContainer  />
        </>
    );
};

import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const PublicRoute = ({ element: Element }: any) => {
    const { isLoggedIn } = useContext(AuthContext);
    const token = localStorage.getItem("token");

    return !isLoggedIn && !token ? <Element /> : <Navigate to="/account" replace />;
};

export default PublicRoute;
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 35,
            color: `#fff`,
            
        }}
        spin
    />
);

const Loader = () => {
  console.log("loadingloading")

    return (
        <div className="short-ring">
            {/* <span></span> */}
            {/* <Spin style={{  backgroundColor:"#C10037 !important"}} indicator={antIcon} /> */}
        </div>
    )
}

export default Loader
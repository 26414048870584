import React, { useEffect, useState } from "react";
import WalletVal from "../validation/WalletVal";
import useRequest from "../hooks/useRequest";
import { apiPath } from "../constant/ApiRoutes";
import { Severty, ShowToast } from "../helper/toast";
import QrCodeModels from "./QrCodeModel";
import Loader from "./Loader";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";

const WalletModal = ({ open, setOpen,walletData }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [channelList, setChannelList] = useState<any>();
  const navigate = useNavigate()
  const { request } = useRequest();

  useEffect(() => {
    getChannel();
  }, []);
  const getChannel = () => {
    request({
      url: apiPath.getChannels,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setChannelList(data?.data?.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const onCreate = (values: any) => {
    navigate('/payment', { state: values })
  };
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={open}
      width={400}
      title="Deposite Modal"
      okText="Submit"
      onCancel={handleClose}
      style={{ background: "rgba(0, 0, 0, 0.5)" }}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: isLoading,
      }}
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        autoComplete="off"
        layout="vertical"
        name="setting_form"
      >
        <Row gutter={[24, 0]}>
          <Divider
            orientation="left"
            orientationMargin={15}
            className="devider-color"
          >
            Wallet
          </Divider>
          <Col span={24}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                // { required: true, message: "Please enter amount!" },
                {
                  validator: (_, value) => {
                    if (value === undefined || value === '') {
                      return Promise.reject(new Error('Please enter amount!'));
                    }
                    if (value < 250) {
                      return Promise.reject(new Error('Amount should be minimum ₹250!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                type="number"
                placeholder="Enter amount"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Select Payment Channel"
              name="channelId"
              rules={[
                { required: true, message: "Please select a channal type!" }, // Change the message  {
              ]}
            >
              <Select placeholder="Select Result Type" allowClear>
                {channelList?.length > 0 &&
                  channelList.map((item: any) => {
                    return (
                      <Select.Option value={JSON.stringify(item)}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WalletModal;

export const IncButton = ({handleClick}:any) => {
    return (
        <div  className="wbets-opt-list text-center">
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",10)}}><span > 10 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",100)}}><span > 100 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",500)}}><span > 500 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",1000)}}><span > 1000 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",1500)}}><span > 1500 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",2000)}}><span > 2000 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",3000)}}><span > 3000 </span></button>
        <button  className="btn bet-opt" onClick={()=>{handleClick("Inc",5000)}}><span > 5000</span></button>
        
      </div>
    )
}
import React, { useContext, useEffect, useState } from "react";
import WalletVal from "../validation/WalletVal";
import useRequest from "../hooks/useRequest";
import { apiPath } from "../constant/ApiRoutes";
import { Severty, ShowToast } from "../helper/toast";
import { SettingContext } from "../context/SettingContext";
import { AuthContext } from "../context/AuthContext";
import Loader from "./Loader";
import { Button, Card, Col, Divider, Form, Input, Modal, Row } from "antd";

const WithdrawalModel = ({
  setOpen,
  open,
  onClose,
  walletData,
  getTransaction,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [winningAmount, setWinningAmount] = useState<number>(0);
  const { request } = useRequest();
  // const { settingDetails } = useContext(SettingContext);
  const { userProfile } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    Winningamount();
  }, []);

  let Winningamount = () => {
    request({
      url: apiPath.winningAmount,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setWinningAmount(data.data.amount);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const onSubmit = (values: any) => {
    let userInput = {
      amount: Number(values.amount),
    };
    // if (Number(balance < settingDetails.min_withdrawal))
    //   return setBalanceError(
    //     `${settingDetails.min_withdrawal} is minimum withdrawal amount`
    //   );
    setIsLoading(true);
    request({
      url: apiPath.withdrawalRequest,
      method: "POST",
      data: userInput,
      onSuccess: (data) => {
        setIsLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          walletData();
          setOpen(false);
          getTransaction("Debit");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={open}
      width={400}
      title="Withdrawal Modal"
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={winningAmount > 0 || !userProfile?.bank_info ? true : false}
          htmlType="submit"
          form="create"
          type="primary"
          loading={isLoading}
        >
          Submit
        </Button>
      ]}
    >
      <Form
        id="create"
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        name="setting_form"
      >
        <Row gutter={[24, 0]}>
          <Divider
            orientation="left"
            orientationMargin={15}
            className="devider-color"
          >
            Wallet
          </Divider>
          <Col span={24}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  validator: (_, value) => {
                    if (value === undefined || value === "") {
                      return Promise.reject(new Error("Please enter amount!"));
                    }
                    if (value < 100) {
                      return Promise.reject(
                        new Error("Amount should be minimum ₹100!")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                type="number"
                placeholder="Enter amount"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {userProfile?.bank_info ? (
        <Card title="Bank Details" className="d-flex">
          <label>Name :- {userProfile?.bank_info?.account_holder}</label>
          <label>IFSC :- {userProfile?.bank_info?.ifsc_code}</label>
          <label>Acc No. :- {userProfile?.bank_info?.account_number}</label>
        </Card>
      ) : (
        <Card title="No Bank Details Added" className="d-flex"></Card>
      )}
      <Card title="NOTE">
        <ul className="custom-list">
          <li>
            Need to bet: <i>₹{winningAmount?.toFixed(2)}</i> to be able to
            withdrawal
          </li>
          <li>
            Withdrawal time <i>00:00-23:59</i>
          </li>
          <li>
            Withdrawal amount range <i>₹110-₹10,00,000</i>
          </li>
          <li>
            Please confirm your beneficial amount information before
            withdrawing. If your information is incorrect, our company will not
            be liable for the amount loss.
          </li>
          <li>
            If your beneficial information is incorrect, Please contact customer
            service.
          </li>
        </ul>
      </Card>
    </Modal>
  );
};

export default WithdrawalModel;

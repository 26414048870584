import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Typography, Divider, Modal } from "antd";
import { QRCode } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../comman/Header";
import useRequest from "../../hooks/useRequest";
import { apiPath } from "../../constant/ApiRoutes";
import { Severty, ShowToast } from "../../helper/toast";

const { Title, Text } = Typography;

const PaymentPage = () => {
  const [balance, setBalance] = useState<number>(0);
  const [upiId, setUpiId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [num, setNum] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [txnRefId, setTxnRefId] = useState<string>("");
  const [txnError, setTxnError] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();
  const amnt = location.state;
  const { request } = useRequest();
  const chanelObj = JSON.parse(amnt.channelId);
  console.log("djbfsdjfbsd", amnt);
  useEffect(() => {
    if (amnt) {
      const min = 10000000;
      const max = 99999999;
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      setNum(randomNumber);
      setBalance(amnt?.amount);
      setUpiId(chanelObj?.key);
      setName(chanelObj?.url);
    }
  }, []);
  const handleCompletePayment = () => {
    if (!txnRefId) return setTxnError("This field is required!!");
    if (txnRefId.length != 12) return setTxnError("Utr must be 12 digit!!");

    const userInput = {
      balance: balance,
      upiId: upiId,
      utr: txnRefId,
    };
    setLoading(true);
    request({
      url: apiPath.thirdPartyBalanceRequest,
      method: "POST",
      data: userInput,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setOpen(false);
          ShowToast(data.message, Severty.SUCCESS);
          navigate("/wallet");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelOrder = () => {
    navigate("/wallet");
  };
  const handleChange = (e: any) => {
    setTxnError("");
    setTxnRefId(e.target.value);
  };


  const handleImageClick=()=>{

  }
  const handlePhonePePayment = () => {
    // Define your PhonePe deep link parameters
   
    // const returnUrl = 'https://example.com/payment/success'; // Your return URL after payment completion

    // Construct the deep link URL
    const deepLinkURL =  `phonepe://pay?pa=${upiId}&pn=${name}&am=${balance}&tn=${num}&tr=${num}`

    // Open the PhonePe deep link
    window.location.href = deepLinkURL;
  };
  const handlePaytmPePayment = () => {
    // Define your PhonePe deep link parameters
   
    // const returnUrl = 'https://example.com/payment/success'; // Your return URL after payment completion

    // Construct the deep link URL
     const deepLinkURL =  `paytmmp://pay?pa=${upiId}&pn=${name}&am=${balance}&tn=${num}&tr=${num}`
    // Open the PhonePe deep link
    window.location.href = deepLinkURL;
  };


    
  const handlegpayPayment = () => {
    // Define your PhonePe deep link parameters
   
    // const returnUrl = 'https://example.com/payment/success'; // Your return URL after payment completion

    // Construct the deep link URL
    const deepLinkURL = `upi://pay?pa=${upiId}&pn=${name}&am=${balance}&tn=IT${num}&tr=IT${num}`;
    // Open the PhonePe deep link
    window.location.href = deepLinkURL;
  };
  return (
    <>
      <Header />
      <div className="payment-page">
        <Card className="payment-cards">
          <Title level={3}>Payments</Title>
          <Text type="danger">have you paid successfully?</Text>
          <Divider />
          <Text>Paytm, PhonePe, GooglePay, Other Bank</Text>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
     
          <img
        src="../../img/gpay.png"
        alt="Gpay"
        style={{ width: '20%', maxWidth: '100px', cursor: 'pointer' }}
        onClick={() => handlegpayPayment()}
      />
      <img
   src="../../img/paytm.svg"
        alt="Image 2"
        style={{ width: '20%', maxWidth: '100px', cursor: 'pointer' }}
        onClick={() => handlePaytmPePayment()}
      />
      <img
   src="../../img/phone.png"
        alt="Image 3"
        style={{ width: '20%', maxWidth: '80px', cursor: 'pointer' }}
        onClick={() => handlePhonePePayment()}
      />
      {/* {selectedImage && (
        <div>
          <h2>Selected Image</h2>
          <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%' }} />
        </div>
      )} */}
    </div>

          <Form layout="vertical" className="payment-form">
            <Form.Item label="UPI ID">
              <Input value={upiId} readOnly />
            </Form.Item>
            <Form.Item label="Balance">
              <Input value={balance} readOnly />
            </Form.Item>
            <QRCode
              value={`upi://pay?pa=${upiId}&pn=${name}&am=${balance}&tn=IT${num}&tr=IT${num}`}
            />
            <Button type="default" block className="save-qr-button">
              Save QR code
            </Button>
            <Form.Item label="UTR (UPI Ref. ID)">
              <Input
                placeholder="Please enter a 12-digit UTR"
                value={txnRefId}
                onChange={handleChange}
              />
              <Text type="danger">{txnError}</Text>
            </Form.Item>
            <Card
              type="inner"
              title="Recharge instructions"
              className="instructions-card"
            >
              <Text>
                <ul>
                  <li>
                    After completing the UPI transaction, please fill in Ref
                    NO./utr If you do not fill in UTR, the loss of customer, our
                    company will not be responsible. Please be sure to fill it
                    back!
                  </li>
                  <li>
                    If you are wrong when you create an order, the loss of
                    customer, our company will not be responsible!
                  </li>
                </ul>
              </Text>
            </Card>
            <div className="button-group">
              <Button
                type="default"
                danger
                block
                onClick={() => {
                  setOpen(true);
                }}
              >
                Cancel the order
              </Button>
              <Button type="primary" block onClick={handleCompletePayment}>
                Complete payment
              </Button>
            </div>
          </Form>
        </Card>
      </div>
      <Modal
        title="Confirm Cancel"
        visible={open}
        onOk={handleCancelOrder}
        onCancel={() => {
          setOpen(false);
        }}
        okText="Yes, Logout"
        cancelText="Cancel"
      >
        <p>Are you sure you want to Cancel Payment?</p>
      </Modal>
    </>
  );
};

export default PaymentPage;

import { Card, Row } from "antd"
import { Footer } from "../../comman/Footer"
import Header  from "../../comman/Header"

export const NotFound = () => {
    return (
        <>
        <Header/>
        <div className="card-container fdh">
        <h3>Page Not Found</h3>
        <h3>402</h3>
    </div>
        </>
    )
}

import {  Button, Card } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";

const ResultAnoucement = ({
  userBets,
  winnerInfo,
  win,
  lose
}: any) => {
    const [open, setOpen] = useState<boolean>(false)
    const [amount,setAmount] = useState<number>(0)
    const [title,setTitle] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const audioWiinning = useRef(new Audio("img/winning.wav"))
    const audioLosing = useRef(new Audio("img/lossbet.wav"))
  
    const handleWinSound = () => {
        try {
          audioWiinning.current.play();
          setTimeout(()=>{
          audioWiinning.current.pause();
          },5000)
      } catch (error) {
        console.error('Error playing/pausing audio:', error);
      }
      
    };
    const handleLoseSound = () => {
        try {
          audioLosing.current.play();
          setTimeout(()=>{
          audioLosing.current.pause();
          },5000)
      } catch (error) {
        console.error('Error playing/pausing audio:', error);
      }
     
    };

    useEffect(()=>{
        if(Object.keys(winnerInfo).length > 0 && winnerInfo?.game_id === userBets[0]?.game_id ){
            setOpen(true)
            setTimeout(()=>setOpen(false),5000)
            if(userBets[0]?.boxId === winnerInfo?.boxId){
                setAmount(userBets[0]?.deposit_amount * userBets[0]?.xValue)
                setMessage("You win!!")
                if(win){
                  handleWinSound()
                  // const wins = document.getElementById("winSound")
                  // wins?.click()
                }
                setTitle(userBets[0]?.title)
            }else{
                setAmount(userBets[0]?.deposit_amount)
                setMessage("You lose!!")
                setTitle(winnerInfo?.winningTitle)
                if(lose){
                  // const lose = document.getElementById("loseSound")
                  // lose?.click()
                  handleLoseSound()
                }
            }
        }
             
      },[winnerInfo.boxId])


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        {open && 
        <div className="resultCard resule_card d-flex fdh">
        <Card extra={
        <CloseOutlined
          onClick={handleClose}
          style={{ fontSize: '16px', color: '#ff4d4f', cursor: 'pointer' }}
        />
      }
       title={`${message}  ${amount}₹`}>
                <label className="fdh mb-0">{title}</label>
        </Card>
        </div> }   
        </> 
  );
};

export default ResultAnoucement;

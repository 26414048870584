import { ReactNode, createContext, memo, useEffect, useMemo, useState } from "react"
import useRequest from "../hooks/useRequest"
import { apiPath } from "../constant/ApiRoutes"
import { Severty, ShowToast } from "../helper/toast"
import { useNavigate } from "react-router-dom"
import { Button, Modal } from "antd"
export const AuthContext = createContext<any>({})

export const AuthProvider = memo(({children}:any) => {
    const[isLoggedIn,setIsLoggedIn] = useState<Boolean>(false)
    const[isLoggedOutModalOpen,setIsLoggedOutModalOpen] = useState(false)
    const[userProfile,setUserProfile] = useState<Object>({})
    
    const { request } = useRequest()

    useEffect(()=>{
      const token  = localStorage.getItem("token")
        if(token){
          getProfile()
        }
    },[])
    const getProfile = () => {
      console.log("setLoggedIn",isLoggedIn)
      request({
        url: apiPath.userprofile,
        method: "GET",
        onSuccess: (data) => {
          if (data.status) {
            setIsLoggedIn(true)
            localStorage.setItem("userId", data.data._id);
            setUserProfile(data.data);
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          if(error.response.data.message === "invalid signature"){
            ShowToast("Your Token is Expire! Login again", Severty.ERROR);
            logout()
          }
          ShowToast(error.response.data.message, Severty.ERROR);
        },
      });
    }

    const handleLogout = () => {
    setIsLoggedOutModalOpen(false)
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    setIsLoggedIn(false);
    setUserProfile({});
    ShowToast("Logout Successfully", Severty.SUCCESS);
    }

    const logout = () => {
      setIsLoggedOutModalOpen(true)
    }
    const values = useMemo(()=>({isLoggedIn,userProfile,setIsLoggedIn,setUserProfile,logout,getProfile}),[userProfile])
    return (
        <>
        <AuthContext.Provider value={values}>
        {children}
        </AuthContext.Provider>
        <div>
      <Modal
        title="Confirm Logout"
        visible={isLoggedOutModalOpen}
        onOk={handleLogout}
        onCancel={()=>{setIsLoggedOutModalOpen(false)}}
        okText="Yes, Logout"
        cancelText="Cancel"
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
        </>
    )
})
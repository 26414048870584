import { useState } from "react";
import useRequest from "../hooks/useRequest";
import { apiPath } from "../constant/ApiRoutes";
import { Severty, ShowToast } from "../helper/toast";
import { Col, Divider, Form, Input, Row, Modal } from "antd";
 
const BankModel = ({ isOpen, setOpen,getProfile }:any) => {
    const [form] = Form.useForm();
    const[isLoading, setIsLoading] = useState<boolean>(false)
    const { request } = useRequest()
 

   const handleSubmit = (values:any) => {
    console.log("valuesvaluesvalues",values)
    const userBankInfo = values
    setIsLoading(true)
            request({
                url: apiPath.bankInfo,
                method: "POST",
                data: userBankInfo,
                onSuccess: (data) => {
                  setIsLoading(false);
                  console.log("walletwallet",data)
                  if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    handleClose()
                    getProfile()
                  } else {
                    ShowToast(data.message, Severty.ERROR);
                  }
                },
                onError: (error) => {
                  ShowToast(error.response.data.message, Severty.ERROR);
                },
              });
            

    }
    const handleClose = () => {
      setOpen(false)
      form.resetFields();
    }
 
    return (
      <>
        <Modal
      visible={isOpen}
      width={400}
      // title="Bank"
      okText="Submit"
      onCancel={handleClose}
      style={{ background: "rgba(0, 0, 0, 0.5)" }}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: isLoading,
      }}
    >
      <Form
        id="create"
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        name="setting_form"
      >
        <Row gutter={[24, 0]}>
          <Divider
            orientation="left"
            orientationMargin={15}
            className="devider-color"
          >
            Bank Details
          </Divider>
          <Col span={24}>
            <Form.Item
              label="Account Holder Name"
              name="account_holder"
              rules={[
                { required: true, message: "Please enter account holder name!" },
                {min:3,message:"Invalid name minimum 3 digit"},
                {max:100,message:"Invalid name"},
              ]}
            >
              <Input
                autoComplete="off"
                type="text"
                placeholder="Enter account holder"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="IFSC Code"
              name="ifsc_code"
              rules={[
                // { required: true, message: "Please enter ifsc code!" },
                {
                  validator: (_, value) => {
                    if (value === undefined || value === '') {
                      return Promise.reject(new Error('Please enter ifsc code!'));
                    }
                    if(!/^[A-Za-z]{4}\d{7}$/.test(value)){
                      return Promise.reject(new Error('Invalid ifsc code!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                type="text"
                placeholder="Enter ifsc code"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Account Number"
              name="account_number"
              rules={[
                // { required: true, message: "Please enter account number!" },
                {
                  validator: (_, value) => {
                    if (value === undefined || value === '') {
                      return Promise.reject(new Error('Please enter account number!'));
                    }
                if (!/^\d+$/.test(value)) {
                  return Promise.reject('Invalid account number format (only digits allowed)!');
                }
                    if (value.length < 8 || value.length > 20) {
                  return Promise.reject('Account number must be between 8 and 20 digits long!');
                }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                type="text"
                placeholder="Enter account number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
        </>
    );
};
 
export default BankModel;
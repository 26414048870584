import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ element: Element }:any) => {
    const { isLoggedIn } = useContext(AuthContext);
    const token = localStorage.getItem("token")
    console.log("iloggedin",isLoggedIn)
  
    return isLoggedIn || token ? <Element /> : <Navigate to="/login" replace />;
  };

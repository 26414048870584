import { useContext, useEffect, useRef, useState } from "react";

import useGame from "../../hooks/useGame";
import { AuthContext } from "../../context/AuthContext";
import { LoaderPage } from "./LoaderPage";
import Loader from "../../component/Loader";
import { SocketContext } from "../../context/SocketContext";
import { IncButton } from "./box/IncButton";
import { Severty, ShowToast } from "../../helper/toast";
import ResultAnoucement from "../../component/ResultAmouncementModal";
import { Checkbox, Radio, Switch } from "antd";
import { Footer } from "../../comman/Footer";

import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

import faker from '@faker-js/faker';
import { apiPath, baseURL } from "../../constant/ApiRoutes";
import useRequest from "../../hooks/useRequest";
import axios from "axios";

// Define types for user and state
type User = {
  icon: string;
  username: string;
  amount: number;
};

export const Main = () => {
  const [selectedBetTab, setSelectedBetTab] = useState<number>(0);
  const [boxId, setBoxId] = useState<string>("");
  const [copyId, setCopyId] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [resetButtonAmount, setResetButtonAmount] = useState<number>();
  const [betAmount, setBetAmount] = useState<number>(10);
  const [winSound, setWinSound] = useState<boolean>(true);
  const [loseSound, setLoseSound] = useState<boolean>(true);
  const [randomNumm , setRandomNum] = useState('')

  const navigate = useNavigate();
  const {
    isLoading,
    gameData,
    x,
    totalBets,
    winnerList,
    winnerInfo,
    isGameEnd,
  } = useContext(SocketContext);
  const { userProfile } = useContext(AuthContext);

  const handle=()=>{
    const token = localStorage.getItem('token');
    if(token){
      navigate('/account')
    }else{
      navigate('/login')
    }
  }
  const {
    handleDeposit,
    fixData,
    fetchBets,
    userBets,
    isButtonDisable,
    setIsButtonDisable,
  }: any = useGame();





 

  const handleAmountClick = (type: string, amnt: number) => {
    if (type == "Dec" && betAmount >= 10) {
      setBetAmount(betAmount - amnt);
    } else {
      if (amnt >= 10 && resetButtonAmount !== amnt) {
        setResetButtonAmount(amnt);
        setBetAmount(amnt);
      } else {
        setBetAmount(betAmount + amnt);
      }
    }
  };

  const handleBetClick = () => {

    const token = localStorage.getItem('token');
    if(token){
      if(betAmount>8000){
        ShowToast(`Bet amount must be 8000 or less!!`, Severty.ERROR);
        return 
      }
      if (x == 0) {
        ShowToast(`Please wait for game restart!!`, Severty.ERROR);
      } else {
        setDisabled(true);
        handleDeposit({ betAmount, boxId });
      }
    }else{
      navigate('/login')
    }


  };

   const handleAmountChange = (e: any) => {
    let value = e.target.value;
    const pattern = /^(?!0\.00)[1-9]\d*(\.\d{1,2})?$/;

    if (pattern.test(value) || value === '') {
      setBetAmount(value);
    }
  };
  var tokenstatus = localStorage.getItem('token')

  const handleBoxIdChange = (item: any) => {
    setBoxId(item._id);
  };
  const handleCopy = (_id: string) => {
    setCopyId(_id);
    navigator.clipboard.writeText(_id);
  };

  const iconData = [
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-cartoon-character-with-glasses-bow-tie_1142-41433.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-human-avatar-profile_23-2150671142.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-person-with-glasses_23-2149436185.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-person-with-long-hair_23-2149436197.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-person-with-sunglasses_23-2149436188.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-illustration-teenager-with-funny-face-glasses_1142-50955.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-render-little-boy-with-glasses-tie-white-background_1142-32328.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/3d-rendering-kid-playing-online_23-2150898480.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/cartoon-character-with-handbag-sunglasses_71767-99.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/cartoon-character-with-yellow-jacket-sunglasses_71767-101.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/doll-with-big-blue-eyes-yellow-jacket-that-says_1340-29126.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/fashion-boy-with-yellow-jacket-blue-pants_71767-96.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/view-3d-woman_23-2150709984.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/view-3d-man-holding-laptop_23-2150709818.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/view-3d-cool-modern-bird_23-2150946449.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/view-3d-confident-businessman_23-2150709932.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/little-fashion-girl-with-bag_71767-100.jpg" },
    { "icon": "https://ragmandata-2000.s3.ap-northeast-1.amazonaws.com/jugaduservice/avatar/little-fashion-girl-with-bag_71767-100.jpg" }
  ];



  const [users, setUsers] = useState<{ icon: string; username: string; amount: number }[]>([]);

  // Function to generate a random color (placeholder for avatar)
const generateRandomIcon = () => {
  const randomIndex = Math.floor(Math.random() * iconData.length);
  return iconData[randomIndex].icon;
};

  // Function to generate random usernames
  const generateRandomUsername = () => {
    return 'user' + Math.floor(Math.random() * 1000);
  };

  // Function to generate random amounts between 10k and 50k
  const generateRandomAmount = () => {
    return Math.floor(Math.random() * (50000 - 2000) + 500);
  };

  const [loading, setLoading] = useState<boolean>(false);
  // Function to generate 20 fake users
  const generateFakeUsers = () => {
setLoading(true)
    const newUsers = [];
    for (let i = 0; i < 40; i++) {
      const icon = generateRandomIcon(); // Using random color as a placeholder for avatar
      const username = generateRandomUsername();
      const amount = generateRandomAmount();
      newUsers.push({ icon, username, amount });
    }
    setUsers(newUsers);
    setTimeout(() => {
      
      setLoading(false)
    }, 3000);
   
  };



  

  const [currentNumber, setCurrentNumber] = useState<number>(10000);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const generateRandomNumber = () => {
      const min = 50000;
      const max = 150000;
      return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const startIncreasing = () => {
      if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
      }
      if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
      }

      intervalRef.current = setInterval(() => {
          setCurrentNumber((prevNumber) => {
              const nextNumber = prevNumber + Math.floor(Math.random() * 1000) + 1;
              return nextNumber >= 200000 ? 200000 : nextNumber;
          });
      }, 100); // Adjust the interval time as needed

      timeoutRef.current = setTimeout(() => {
          if (intervalRef.current !== null) {
              clearInterval(intervalRef.current);
          }
          const finalRandomNumber = generateRandomNumber();
          setCurrentNumber(finalRandomNumber);

          // Schedule reset to 10,000 after displaying the final random number
          setTimeout(() => {
              // setCurrentNumber(10000);
          }, 15000); // Adjust the delay before reset as needed (e.g., 5000ms = 5 seconds)
      }, 12000); // Stop after 15 seconds
  };

  const resetNumber = () => {
      if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
      }
      if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
      }
      setCurrentNumber(0);
     
     
  };

  useEffect(() => {
      return () => {
          if (intervalRef.current !== null) {
              clearInterval(intervalRef.current);
          }
          if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current);
          }
      };
  }, []);

  useEffect(()=>{
    generateFakeUsers();
  },[])

  useEffect(()=>{
    if(winnerInfo?.boxId){
      generateFakeUsers();
    }
  },[winnerInfo])

  useEffect(()=>{
  
 
    startIncreasing();
  },[isGameEnd])


  useEffect(() => {
    if (winnerInfo.boxId) {
      setIsButtonDisable(false);
      fetchBets();
      resetNumber()
    }

  }, [winnerInfo, isGameEnd]);

	const { request } = useRequest();
  const [trData, setTrData] = useState([]);
  useEffect(()=>{
    fetchTransaction();
    // window.location.reload();
  },[isGameEnd])

  const fetchTransaction = () => {
    setTrData([])
    axios({
      method:'GET',
      url:baseURL+apiPath.getGameTrnsaction,
  })
      .then(function (response) {
      console.log("response?.data",response?.data);
      setTrData(response?.data);

      })
      .catch(function (error) {
           console.log(error?.response)
       
 
       
      });

	};


  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <>
          <div className="game_main">
            <div className="game_top d-flex in_nav align-items-center">
              <Link to="/account">
              <img
                src="img/logo.png"
                alt="header-Logo "
                className="logo in_nav_logo"
              />
              </Link>
              <div className="ml-auto">
                <i className="fa fa-inr"></i> {fixData?.balance?.toFixed(2)}
              </div>
              <div
                className="ml-2 ml-md-4"
                onClick={handle}
              >
                <span className="user_img mr-1 mr-sm-3">
                  <img src={userProfile?.social_image} />
                </span>
                {userProfile?.name || "USER"}
              </div>
            </div>
            <div className="game_page">
              <div className="game_right order-md-2">
              <div className="order-md-2 padding-class">
              <div className="w_box">
                  <ul className="d-flex justify-content-between">
                    <li className="d-flex">
                      <h6 className="color2">
                        Winning Sound
                      </h6>
                      <Switch
                      className={!winSound?"switch-class":"switch-class-checked"}
                        id="winSound"
                        checked={winSound}
                        onChange={()=>{setWinSound(!winSound)}}
                      />
                    </li>
                    <li className="d-flex">
                      <h6 className="color2">
                        Losing Sound
                      </h6>
                      <Switch
                      className={!loseSound?"switch-class":"switch-class-checked"}
                        id="loseSound"
                        defaultChecked={loseSound}
                        onChange={()=>{setLoseSound(!loseSound)}}
                      />
                    </li>
                  </ul>
              </div>
              </div>
                <div className="w_box d-flex justify-content-between fdh">
                  <b className="color2 w100 ">Total Bet - {currentNumber}</b>

                  <div className=" w100 text-center">
                    {winnerInfo?.boxId ? (
                      "Please Wait Game Starting..."
                    ) : x > 0 ? (
                      <b className="mb-0 color1">Timer: {x} seconds</b>
                    ) : (
                      <h6>Waiting for result</h6>
                    )}
                  </div>
                  <b className="color2 w100 mo_none"></b>
                </div>
{/* Color Last Style */}

<div className="game_page"   style={{   display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    padding: '10px',
    gap: '10px', }}>
 {trData.map(({ _id, color }) => {
        return (
            <div
                key={_id} // Use _id as the unique key
                style={{
                    backgroundColor: color, // Access color directly
                    borderRadius: '50%',
                    flexShrink: 0,
                    width: '30px', // Smaller circle size for mobile
                    height: '30px'
                }}
            ></div>
        );
    })}


{/* {trData?.map((item)=>(
<>

{window.innerWidth < 600 ? 
     <div
     style={{
    backgroundColor:item.color,
      borderRadius: '50%',
      flexShrink: 0,
      width: '30px', // Smaller circle size for mobile
      height: '30px',
     }}
     
     ></div>
     :  <div
     style={{
      width: '50px', // Circle size
      height: '50px',
      backgroundColor: 'black',
      borderRadius: '50%',
      flexShrink: 0,
     }}
     
     ></div>
    }
</>
))} */}

  
    </div>
   


                <div>
                  <ResultAnoucement
                    userBets={userBets}
                    winnerInfo={winnerInfo}
                    win={winSound}
                    lose={loseSound}
                  />

<div className="p-3 fdh">
  <div className="container">
    <div style={{marginBottom:'20px'}} className="h45 row justify-content-center">
      {x > 0 || !winnerInfo?.boxId ? (
        gameData?.boxs?.map((item: any, i: number) => {
          return (
            <div key={i} className="col-md-3 col-6 text-center ">
              <div
                className={
                  item?._id !== boxId
                    ? `gr_box${i + 1} gr_box_p box-class`
                    : `gr_box${i + 1} gr_box_p box-class-click`
                }
                onClick={() => {
                  handleBoxIdChange(item);
                }}
              >
                <img
                  src={`img/${i}.png`}
                  alt="magic"
                  // width={40}
                  // height={20}
                />
                <h3>{item?.xValue?.toFixed(2)}X</h3>
                <h5>{item?.title}</h5>
              </div>
            </div>
          );
        })
      ) : (
        <div className="ring">
          Loading <span></span>
        </div>
      )}
    </div>
  </div>
</div>

                </div>

                <div className="row">
                  <div className="col-md-12 mb-mb-0 mb-4">
                    <div className="gr_box gr_box_p">
                      <h6 className="text-center ttu">Place Your Bet</h6>
                      <div className="tab-content">
                        <div
                          id="Bet"
                          className="tab-pane  in active text-center"
                        >
                          <div className="bet-block">
                            <IncButton handleClick={handleAmountClick} />
                          </div>
                          <div className="mt-4 auto-game-feature auto-game d-md-inline-flex     align-items-center">
                            <div className=" mr-sm-3 mb-sm-0 mb-3 col-md-6">
                              <input
                                type="number"
                                style={{width:"200px"}}
                                max={8000}
                                className="form-control2"
                                value={betAmount}
                                onChange={handleAmountChange}
                              />
                            </div>
                            <div className=" ng-untouched ng-valid ng-dirty  mr-3">
                              <div className="spinner big">
                                <div className="buttons">
                                  <button
                                    type="button"
                                    className="minus ng-star-inserted"
                                    onClick={() => {
                                      handleAmountClick("Dec", 1);
                                    }}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                </div>
                                <div className="input full-width">
                                  <input
                                    type="number"
                                    disabled
                                    min={0}
                                    max={8000}
                                    className="font-weight-bold"
                                    value={betAmount}
                                    onChange={handleAmountChange}
                                  />
                                </div>
                                <div className="buttons">
                                  <button
                                    type="button"
                                    className="plus ng-star-inserted"
                                    onClick={() => {
                                      handleAmountClick("Inc", 1);
                                    }}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="buttons-block">
                              <button
                                className="btn btn_be bet ng-star-inserted text-uppercase align-items-center d-flex"
                                onClick={handleBetClick}
                                disabled={
                                  isButtonDisable ||
                                  gameData?._id === userBets[0]?.game_id
                                }
                              >
                                <span className="mr-2">Bet</span>{" "}
                                <span>{betAmount} ₹</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
{tokenstatus ? 
              
              <div className="game_left order-md-1">
                <ul className="nav nav_btn mb-2 d-block text-center">
                  <li className="d-inline-block">
                    <a
                      data-toggle="tab"
                      className={selectedBetTab === 0 ? "active" : ""}
                      onClick={() => setSelectedBetTab(0)}
                    >
                     Last Game Winner 
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      data-toggle="tab"
                      className={selectedBetTab === 1 ? "active" : ""}
                      onClick={() => setSelectedBetTab(1)}
                    >
                      My Bets
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  {selectedBetTab == 0 && (
                    <div
                      id="AllBets"
                      className={`tab-pane ${
                        selectedBetTab === 0 ? "in active" : "fade"
                      }`}
                    >
                      {/* <div className='mb-2'> All Bets 610406</div> */}
                      <div className="game_left_s ">
                        <table className="table">
                          {loading ?    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                    <ClipLoader color="#3498db" size={25} />
                </div> : 
                          <tbody>
                            {users?.map((bet: any) => (
                              <tr
                                style={
                                  bet?.status === "Placed"
                                    ? { background: "green" }
                                    : {}
                                }
                                // key={bet?._id}
                              >
                                <td>
                                  <span className="">
                                    <img
                                      src={
                                        bet?.icon
                                      }
                                      className="user_w"
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="">{bet?.username?.slice(0,1)}*****{bet?.username?.slice(5,6)}</span>
                                </td>
                                <td style={{color:'green'}} className="text-right">
                                  {/* {bet?.deposit_amount * bet?.xValue || 0} */}
                                  +{bet?.amount}
                                  <span className="w_spam">Winning Amount</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
}
                        </table>
                      </div>
                    </div>
                  )}

                  {selectedBetTab == 1 && (
                    <div
                      id="MyBets"
                      className={`tab-pane ${
                        selectedBetTab === 1 ? "in active" : "fade"
                      }`}
                    >
                      <div className="game_left_s ">
                        <table className="table">
                          <thead className="">
                            <tr>
                              <th>Game Id</th>
                              <th className="text-center"> Bet Amount </th>
                              <th className="text-right"> Bet Card </th>
                              <th className="text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userBets?.map((bet: any) => (
                              <tr key={bet?._id}>
                                <td>
                                  <span className="d-flex">
                                    <h6>
                                      {`${bet?.game_id?.slice(0, 4)}...`}{" "}
                                      {copyId !== bet?.game_id ? (
                                        <i
                                          className="fa fa-copy cur"
                                          onClick={() => {
                                            handleCopy(bet?.game_id);
                                          }}
                                        ></i>
                                      ) : (
                                        <i className="fa fa-check"></i>
                                      )}
                                    </h6>
                                  </span>
                                </td>
                                <td className="text-center">
                                  {bet?.deposit_amount?.toFixed(2)}
                                </td>
                                <td>
                                  <span className="text-right">
                                    {bet?.title}
                                  </span>
                                </td>
                                <td className="text-right">
                                  {bet?.status == "loser"
                                    ? "Lose"
                                    : bet?.status}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              :null}

            </div>

            <div className="box_popop gr_box">
              <h3 className="m-auto fdh mb-0">00:05</h3>
            </div>
            <div className="game_starting ">
              <div className="m-auto">
                <h3 className=" fdh mb-0"> Game Is Starting...</h3>
              </div>
            </div>
          </div>
        </>
      )}
     <Footer/> 
    </>
  );
};
